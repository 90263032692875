@import "../fonts/fonts.scss";

@mixin typography() {
  @each $name, $fontSize, $fontWeight, $lineHeigth, $letterSpacing in $list {
    &-#{$name} {
      font-size: $fontSize;
      font-family: $fontWeight;
      font-weight: normal;
      line-height: $lineHeigth;
      letter-spacing: $letterSpacing;
      @if (str-index($name, "heading-")) {
        &--monserrat {
          font-family: $MontserratRegular;
        }
        &--monserrat-semibold {
          font-family: $MontserratSemiBold;
        }
      } @else if (str-index($name, "interactive-")) {
        color: var(--sherpa-midnight-800);
      }
    }
  }
}

$list: (
  "heading-1" 56px $RobotoMedium 70px -0.2px,
  "heading-2" 48px $RobotoMedium 60px -0.2px,
  "heading-3" 40px $RobotoMedium 50px -0.2px,
  "heading-4" 32px $RobotoMedium 40px 0px,
  "heading-5" 24px $RobotoMedium 36px 0px,
  "heading-6" 20px $RobotoMedium 28px 0.2px,
  "heading-7" 18px $RobotoMedium 24px 0.2px,
  "heading-8" 16px $RobotoMedium 24px 0.2px,
  "title-1" 18px $RobotoMedium 24px 0.2px,
  "title-2" 16px $RobotoMedium 24px 0.2px,
  "title-3" 14px $RobotoMedium 20px 0.2px,
  "body-1" 16px $RobotoRegular 24px 0px,
  "body-2" 14px $RobotoRegular 20px 0px,
  "paragraph-XL" 18px $RobotoRegular 28px 0px,
  "paragraph-L" 16px $RobotoRegular 28px 0px,
  "paragraph-M" 16px $RobotoRegular 24px 0px,
  "paragraph-S" 14px $RobotoRegular 20px 0px,
  "placeholder-1" 18px $RobotoRegular 24px 0.2px,
  "label-1" 14px $RobotoRegular 20px 0.2px,
  "caption-1" 12px $RobotoRegular 18px 0.2px,
  "overline-1" 10px $RobotoRegular 16px 0.4px,
  "interactive-1" 18px $RobotoMedium 24px 0.2px,
  "interactive-2" 16px $RobotoMedium 24px 0.2px,
  "interactive-3" 14px $RobotoMedium 18px 0.2px
);

.sherpa-typography {
  @include typography();
}
