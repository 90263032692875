@import "../../assets/scss/typography";
@import "../../assets/fonts/fonts.scss";

.landing {
  background-color: #f6faff;
  margin: -8px;

  &__footer-image {
    height: 100%;
    width: 100%;
    @media (max-width: 768px) {
      content: url("../../assets/img/header-landing.png");
    }
    @media (min-width: 769px) {
      content: url("../../assets/img/header-landing-XL.png");
    }
  }

  &__services-section {
    padding: 80px 13px;
    background-color: var(--sherpa-white);
    @media screen and (max-width: 320px) {
      padding: 76px 5px;
    }
    &__text {
      font-family: Kiffo-SemiBold, sans-serif;
      width: 328px;
      height: 80px;
    }

    &__component {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 29px;
      margin-top: 16px;
      @media screen and (max-width: 320px) {
        gap: 5px;
      }
    }
  }

  &__financial-info {
    background-color: var(--sherpa-midnight-50);
    padding: 80px 16px 80px 16px;

    &__section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-radius: 16px;
      border-bottom-left-radius: 70px;
      border-bottom-right-radius: 70px;
      border: 1px solid var(--sherpa-carbon-200);
      background: var(--sherpa-white);

      &__content-text {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-self: stretch;
        padding: 32px 24px;

        &__header {
          font-size: 16px;
          line-height: 24px;
          font-family: Kiffo-SemiBold, sans-serif;
          color: #808080;
        }

        &__middle {
          font-size: 32px;
          line-height: 40px;
          font-family: Kiffo-SemiBold, sans-serif;
          color: #000000;
        }

        &__final {
          font-family: Roboto-Light, sans-serif;
          font-size: 16px;
          line-height: 24px;
          color: #444444;
        }

        &__image {
          width: 100%;
        }
      }
    }
  }
}

.card-info-color {
  background-color: var(--sherpa-midnight-50);
}
